import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Display           from '@interness/theme-default/src/components/Display/Display';
import Button            from '@interness/web-core/src/components/elements/Button/Button';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia }     from '@interness/web-core/src/components/_helpers';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import RandomBrandHeaderImages
  from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';

function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Goldschmiede J. Peter Rust Goldschmiedetradition seit 1968</Heading>
          <div style={{width: '250px', margin: 'auto', textAlign: 'center', marginBottom: '20px'}}>
            <Img fluid={findMedia(props.data.indexImages.media, '50-img-1').childImageSharp.fluid}/>
          </div>
          <Img fluid={findMedia(props.data.indexImages.media, '50-img-2').childImageSharp.fluid}/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>DER „ESSENER JUNG“</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier und Goldschmiede J. Peter Rust in Essen, wo traditionelles Handwerk auf zeitgenössische Eleganz trifft. Unsere Goldschmiedemeister führen eine Meisterwerkstatt, die sich der Kunst des Schmuckdesigns und der Uhrmacherei mit Leidenschaft und Präzision widmet. Wir sind stolz darauf, Ihnen maßgeschneiderte Anfertigungen, eine exquisite Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen zu bieten.</p>
            <p>In unserer Goldschmiede J. Peter Rust legen wir großen Wert darauf, jedes Schmuckstück individuell und mit größter Sorgfalt zu gestalten. Unsere Anfertigungen spiegeln Ihre persönlichen Wünsche und Vorstellungen wider, wodurch jedes Stück einzigartig wird. Ob Sie nach dem perfekten Antragsring suchen oder ein ganz persönliches Schmuckstück kreieren möchten, unser Team aus erfahrenen Goldschmiedemeistern steht Ihnen mit kreativen Ideen und fachkundiger Beratung zur Seite.</p>
            <p>Darüber hinaus bieten wir in unserer Meisterwerkstatt einen umfassenden Service für Reparaturen und Wartungen Ihrer Uhren und Schmuckstücke an. Wir verstehen die emotionale Bedeutung, die jedes Stück für Sie hat, und setzen unser handwerkliches Können ein, um es in seinem besten Zustand zu bewahren. Zudem bieten wir einen Altgoldankauf an, der es Ihnen ermöglicht, Ihre alten Schätze in neue, maßgeschneiderte Kreationen umzuwandeln.</p>
            <p>Besuchen Sie uns in Essen und erleben Sie die Welt von Juwelier und Goldschmiedemeister J. Peter Rust. Wir freuen uns darauf, Sie in unserem Geschäft begrüßen zu dürfen und gemeinsam mit Ihnen Schmuckstücke zu kreieren, die nicht nur begeistern, sondern auch Geschichten erzählen.</p>
            <p>Bis bald in Essen, J.Peter Rust und sein Team</p>
          </span>
        </section>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading tag={'h2'}>Von Mensch zu Mensch</Heading>
          <div style={{ textAlign: 'center' }}>
            <p>Erfahren Sie auf der folgenden Seite mehr über J. Peter Rust und sein Team</p>
            <Button as={Link} to={'/ueber-uns'}>Mehr erfahren</Button>
          </div>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Unser virtuelles Schaufenster</Heading>
          <Display/>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    indexImages: directusMediaCollection(name: {eq: "index"}) {
      name
      media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
